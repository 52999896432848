import { DatabaseOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Divider, Drawer, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import FunctionsIcon from '@material-ui/icons/Functions';
import HelpIcon from '@material-ui/icons/Help';
import InboxIcon from '@material-ui/icons/Inbox';
import InputIcon from '@material-ui/icons/Input';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import LocalShipping from '@material-ui/icons/LocalShipping';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StorageIcon from '@material-ui/icons/Storage';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import clsx from 'clsx';
import { MagnifyingGlass } from 'phosphor-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import history from '~/services/history';
import { store } from '~/store';
import { signOut } from '~/store/modules/auth/actions';

import api from '~/services/api';
import InputText from '../InputText';
import { SidebarNav } from './components/SidebarNav';

const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: 0,
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Sidebar(props) {
  const dispatch = useDispatch();

  const { permissions } = store.getState().auth;

  const profile = useSelector((state) => state.user.profile);
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const [downstreamUsers, setDownstreamUsers] = useState([]);

  useEffect(() => {
    async function loadInitialData() {
      const { data: downstreamUsersData } = await api.get('params?service=Downstream&key_param=usersAllowed');
      setDownstreamUsers(downstreamUsersData.split(';'));
    }
    loadInitialData();
  }, []);

  const handleSerialNumberSearch = async (serial_number, event) => {
    if (event.nativeEvent.type === 'click' && serial_number === '') {
      return;
    }

    const serialNumberTrimmed = serial_number.trim().toUpperCase();

    const serialNumberFormatted =
      serialNumberTrimmed.substring(0, 4) === 'P/N:' ? serialNumberTrimmed.substring(serialNumberTrimmed.indexOf('S/N:') + 4) : serialNumberTrimmed;

    if (serialNumberFormatted === '') {
      toast.error('Enter the serial number first');
      return;
    }

    event.currentTarget.value = '';

    const { data: serialNumberData } = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumberFormatted });

    if (serialNumberData.error) {
      toast.error(serialNumberData);
    }

    history.push(`/inventoryProcessing/${serialNumberData.id}`);
  };

  let pages = [
    {
      title: 'Change Location',
      href: '/changeLocation',
      icon: <DatabaseOutlined />,
    },
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />,
    },
    {
      title: 'Items',
      href: '/items',
      icon: <MenuBookIcon />,
    },
    {
      title: 'Inventory (SN-Controlled)',
      href: '/inventory',
      icon: <FunctionsIcon />,
    },
    {
      title: 'Stock By Category',
      href: '/inventorySearch',
      icon: <FunctionsIcon />,
    },
    {
      title: 'Inventory (Qty-Controlled)',
      href: '/inventoryParts',
      icon: <FunctionsIcon />,
    },
    {
      title: 'Batteries',
      href: '/inventoryBatteries',
      icon: <ThunderboltOutlined />,
    },
    {
      title: 'Orders',
      href: '/orders',
      icon: <ShoppingBasketIcon />,
    },
    {
      title: 'Receiving',
      href: '/receiving',
      icon: <InboxIcon />,
    },
    {
      title: 'Stores',
      href: '/stores',
      icon: <BusinessIcon />,
    },
    {
      title: 'Customers',
      href: '/customers',
      icon: <PeopleIcon />,
    },
    {
      title: 'Suppliers',
      href: '/suppliers',
      icon: <LocalShipping />,
    },
    // {
    //   title: 'Technicians',
    //   href: '/technicians',
    //   icon: <BuildIcon />,
    // },
    {
      title: 'Returns',
      href: '/returns',
      icon: <KeyboardReturnIcon />,
    },
    {
      title: 'Tech Area',
      href: '/tech_area',
      icon: <HelpIcon />,
    },
    {
      title: 'Storage',
      href: '/storage',
      icon: <StorageIcon />,
    },
    // {
    //   title: 'Departments',
    //   href: '/departments',
    //   icon: <GroupWorkIcon />,
    // },
    {
      title: 'Assessment',
      href: '/assessment',
      icon: <AssignmentIcon />,
    },
    {
      title: 'Location',
      href: '/location',
      icon: <DatabaseOutlined />,
    },
  ];

  if (profile?.email === 'naz@ruggedbooks.com' || profile?.type === 999) {
    pages.splice(3, 0, {
      title: 'Inventory (w/ batteries)',
      href: '/inventoryComplete',
      icon: <FunctionsIcon />,
    });
  }

  if (profile?.email === 'adolfo.lozano@ruggedbooks.com') {
    pages.push({
      title: 'Reports',
      href: '/reports',
      icon: <AssessmentIcon />,
    });
  }

  if (profile?.type === 999 || profile?.type === 9) {
    pages.push(
      {
        title: 'Admin',
        href: '/admin',
        icon: <SupervisorAccountIcon />,
      },
      {
        title: 'Reports',
        href: '/reports',
        icon: <AssessmentIcon />,
      },
      {
        title: 'Technicians',
        href: '/technicians',
        icon: <BuildIcon />,
      }
    );
  }
  if (profile?.type === 2 || profile?.type === 3 || profile?.type === 9 || profile?.type === 999) {
    pages.push({
      title: 'Purchase Orders',
      href: '/purchaseOrders',
      icon: <ShoppingCartIcon />,
    });
  }

  if (profile?.type === 999 || downstreamUsers.includes(profile?.email)) {
    pages.push({
      title: 'Downstream',
      href: '/downstream',
      icon: <DeleteSweepIcon />,
    });
  }

  if (profile?.type === 3 || profile?.type === 999) {
    pages.push({
      title: 'Instructions',
      href: '/instructions',
      icon: <LocalLibraryIcon />,
    });
  }

  if (profile?.type === 4) {
    pages = [];
  }

  if (profile?.type === 80) {
    pages = [];
    pages.push({
      title: 'Location',
      href: '/location',
      icon: <DatabaseOutlined />,
    });
  }

  const newPages = permissions.length > 0 ? pages.filter((el) => permissions.includes(el.href.split('/')[1])) : pages;

  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
    <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        {profile?.type !== 80 && (
          <InputText
            placeholder="Serial Number"
            style={{
              width: '200px',
            }}
            onPressEnter={handleSerialNumberSearch}
            onBlur={(e) => {
              e.target.value = '';
            }}
            IconAfterProp={<MagnifyingGlass size={20} />}
          />
        )}
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={newPages} />
        {profile?.type !== 4 && <Divider className={classes.divider} />}
        <Hidden lgUp>
          <InputIcon titleAccess="Logout" onClick={handleSignOut} />
        </Hidden>
      </div>
    </Drawer>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
  className: '',
  onClose: () => {},
};
