import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import React, { useCallback, useEffect, useRef } from 'react';

import api from '~/services/api';

import { Container, StyledTextField } from './styles';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function QBO() {
  const classes = useStyles();

  // const [accessToken, setAccessToken] = useState('');
  const accessTokenRef = useRef('');
  const tokenTypeRef = useRef('');
  const refreshExpiresInRef = useRef('');
  const refreshTokenRef = useRef('');
  const accessExpiresInRef = useRef('');
  const clientIdRef = useRef('');
  const clientSecretRef = useRef('');
  const environmentRef = useRef('');
  const redirectUriRef = useRef('');
  const webhookUriRef = useRef('');
  const webhooksVerifierRef = useRef('');

  useEffect(() => {
    async function getParams() {
      const getQBOParams = await api.get('params/show/byService?service=QBO');
      if (getQBOParams.data.length > 0) {
        clientIdRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'client_id')[0]?.value_param || '';
        clientSecretRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'client_secret')[0]?.value_param || '';
        environmentRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'environment')[0]?.value_param || '';
        redirectUriRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'redirect_uri')[0]?.value_param || '';

        accessTokenRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'access_token')[0]?.value_param || '';
        tokenTypeRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'token_type')[0]?.value_param || '';
        refreshExpiresInRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'x_refresh_token_expires_in')[0]?.value_param || '';
        refreshTokenRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'refresh_token')[0]?.value_param || '';
        accessExpiresInRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'expires_in')[0]?.value_param || '';

        webhookUriRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'webhook_uri')[0]?.value_param || '';
        webhooksVerifierRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'webhooks_verifier')[0]?.value_param || '';
      }
    }
    getParams();
  }, []);

  const handleRefreshQBO = useCallback(async () => {
    const refreshToken = await api.get('QBOrefresh');

    accessTokenRef.current.value = refreshToken.data.access_token;
    tokenTypeRef.current.value = refreshToken.data.token_type;
    refreshExpiresInRef.current.value = refreshToken.data.x_refresh_token_expires_in;
    refreshTokenRef.current.value = refreshToken.data.refresh_token;
    accessExpiresInRef.current.value = refreshToken.data.expires_in;
  }, []);

  const handleConnectQBO = useCallback(async () => {
    const opener = async () => {
      const { data } = await api.post('QBOAuthUri', {
        client_id: clientIdRef.current.value,
        client_secret: clientSecretRef.current.value,
        environment: environmentRef.current.value,
        redirect_uri: redirectUriRef.current.value,
      });
      const parameters = 'location=1,width=800,height=650';
      const myWindow = window.open(data.authUri, 'connectPopup', parameters);

      const timer = setInterval(async () => {
        if (myWindow.closed) {
          clearInterval(timer);
          const getQBOParams = await api.get('params/show/byService?service=QBO');
          // setAccessToken(getQBOParams.data.filter(el => el.key_param === 'refresh_token')[0].value_param);
          accessTokenRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'access_token')[0].value_param;
          tokenTypeRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'token_type')[0].value_param;
          refreshExpiresInRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'x_refresh_token_expires_in')[0].value_param;
          refreshTokenRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'refresh_token')[0].value_param;
          accessExpiresInRef.current.value = getQBOParams.data.filter((el) => el.key_param === 'expires_in')[0].value_param;
        }
      }, 1000);
    };
    opener();
  }, []);

  const handleSave = useCallback(async () => {
    const newParams = {
      params: [
        {
          service: 'QBO',
          key_param: 'webhook_uri',
          value_param: webhookUriRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'client_id',
          value_param: clientIdRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'client_secret',
          value_param: clientSecretRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'environment',
          value_param: environmentRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'redirect_uri',
          value_param: redirectUriRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'access_token',
          value_param: accessTokenRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'token_type',
          value_param: tokenTypeRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'x_refresh_token_expires_in',
          value_param: refreshExpiresInRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'refresh_token',
          value_param: refreshTokenRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'expires_in',
          value_param: accessExpiresInRef.current.value,
        },
        {
          service: 'QBO',
          key_param: 'webhooks_verifier',
          value_param: webhooksVerifierRef.current.value,
        },
      ],
    };
    await api.post('params', newParams);
  }, []);

  return (
    <Container>
      <StyledTextField
        label="Client ID"
        id="clientId"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={clientIdRef}
        variant="outlined"
        size="small"
      />
      <StyledTextField
        label="Client Secret"
        id="clientSecret"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={clientSecretRef}
        variant="outlined"
        size="small"
      />
      <StyledTextField
        label="Environment (sandbox or production)"
        id="environment"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={environmentRef}
        variant="outlined"
        size="small"
      />
      <StyledTextField
        label="Redirect URI"
        id="redirectUri"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={redirectUriRef}
        variant="outlined"
        size="small"
      />
      <Button onClick={handleConnectQBO} variant="contained" size="small" color="primary" className={classes.margin}>
        Connect to QBO
      </Button>

      <StyledTextField
        label="Token Type"
        id="tokenType"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={tokenTypeRef}
        variant="outlined"
        size="small"
      />
      <StyledTextField
        label="Refresh Token Expires in"
        id="refreshExpiresIn"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={refreshExpiresInRef}
        variant="outlined"
        size="small"
      />

      <StyledTextField
        label="Access Token"
        id="accessToken"
        InputLabelProps={{
          shrink: true,
        }}
        multiline
        minRows={4}
        inputRef={accessTokenRef}
        variant="outlined"
        size="small"
      />
      <StyledTextField
        label="Refresh Token"
        id="refreshToken"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={refreshTokenRef}
        variant="outlined"
        size="small"
      />
      <StyledTextField
        label="Access Token Expires in"
        id="accessExpiresIn"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={accessExpiresInRef}
        variant="outlined"
        size="small"
      />
      <Button onClick={handleRefreshQBO} variant="contained" size="small" color="primary" className={classes.margin}>
        Refresh Access Token
      </Button>

      <StyledTextField
        label="Webhook URI"
        id="webhookUri"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={webhookUriRef}
        variant="outlined"
        size="small"
      />

      <StyledTextField
        label="Webhook Verifier"
        id="webhooksVerifier"
        InputLabelProps={{
          shrink: true,
        }}
        inputRef={webhooksVerifierRef}
        variant="outlined"
        size="small"
      />

      <Fab size="small" color="primary" aria-label="add" className={classes.margin} onClick={handleSave}>
        <SaveIcon />
      </Fab>
    </Container>
  );
}
