import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  DollarCircleOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  PrinterOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Alert, Button, Form, Input, InputNumber, Popconfirm, Space, Tag, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DebounceSelect } from '~/components/DebounceSelect';
import api from '~/services/api';
import history from '~/services/history';

import {
  ComponentTextArea,
  Container,
  CustomDivider,
  OrderArea,
  OrderAreaProducts,
  OrderContainer,
  OrderSummary,
  OrderTitle,
  ProductsTable,
  ProductsTableShipped,
  SearchProducts,
} from './styles';
import { generatePDF } from './generatePDF';

export default function OrderDetail({ match, location }) {
  const [form] = Form.useForm();
  const { Paragraph } = Typography;
  const profile = useSelector((state) => state.user.profile);

  const [saveConfirmPopup, setSaveConfirmPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  // const [storeId, setStoreId] = useState(null);
  const [orderNumber, setOrderNumber] = useState('');
  const [error, setError] = useState('');
  const [orderDetails, setOrderDetails] = useState({ customer: {}, store: {}, order_items: [{}], checklist: [{ technician: {}, item: {} }] });
  // const [productList, setProductList] = useState([]);
  const [productOrderList, setProductOrderList] = useState([]);
  const [storeItemsList, setStoreItemsList] = useState([]);
  const [loadingSync, setLoadingSync] = useState(false);
  const [value, setValue] = useState([]);
  const [orderNotes, setOrderNotes] = useState('');

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);

    const searchQueryParam = params.get('searchQuery');
    setSearchQuery(searchQueryParam);

    // const storeIdParam = params.get('storeId');
    // setStoreId(storeIdParam);

    async function loadOrder() {
      const response = await api.get(`orders/${match.params.id}`);
      if (response.data.error) {
        setError(response.data.error);
        toast.warn('No orders found!');
        return;
      }
      setOrderDetails(response.data);
      setOrderNotes(response.data.notes);
      setOrderNumber(response.data.order_num);
      setStoreItemsList(response.data.order_items.filter((el) => el.is_rbms_item === false).sort((a, b) => a.id - b.id));
      setProductOrderList(response.data.order_items.filter((el) => el.is_rbms_item === true));

      // Assigning value to the Order items rbms_notes.
      response.data.order_items.forEach((orderItem, index) => {
        form.setFieldsValue({ [`rbms_notes${index}`]: orderItem.rbms_notes });
      });
    }
    loadOrder();
  }, []); //eslint-disable-line

  const fetchItems = async (search) => {
    const productsData = await api.get(`/items?search=${search}`);
    return productsData.data.map((el) => ({
      value: `${el.part_number && el.part_number.toUpperCase()} - ${el.description && el.description.toUpperCase()}`,
      label: `${el.part_number && el.part_number.toUpperCase()} - ${el.description && el.description.toUpperCase()}`,
    }));
  };

  // Function to save the notes added.
  const handleSaveNotes = async () => {
    try {
      orderDetails.order_items.forEach(async (orderItem, index) => {
        await api.put(`orderItems/${orderItem.id}`, { rbms_notes: form.getFieldValue(`rbms_notes${index}`) });
        toast.success('Manual Notes saved successfully');
      });
    } catch (error) {
      toast.error(`${error}. Something went wront. Contact support to explain what happened`);
    }
  };

  const handleVisibleChange = (visible) => {
    setSaveConfirmPopup(!visible);
  };

  const handleAddProduct = useCallback(async () => {
    if (form.getFieldValue('productQtyRef') === '' || form.getFieldValue('productTitleRef') === '') {
      toast.error('Neither Product or Quantity can be 0');
      return;
    }

    const newProductOrderItem = {
      order_id: orderDetails.id,
      title: form.getFieldValue('productTitleRef'),
      unit_price: 0,
      quantity: form.getFieldValue('productQtyRef'),
      total_price: 0,
      rbms_notes: form.getFieldValue('manualNotes'),
      is_rbms_item: true,
    };
    try {
      const newOrderItem = await api.post('orderItems', newProductOrderItem);

      newProductOrderItem.id = newOrderItem.data.id;
      setProductOrderList([...productOrderList, newProductOrderItem]);

      toast.success('Order Saved');

      form.setFieldsValue({ productTitleRef: null, productQtyRef: null, manualNotes: null });

      const response = await api.get(`orders/${match.params.id}`);
      setOrderDetails(response.data);
    } catch (error) {
      toast.error(`${error.response.data.error}. Something went wront. Contact support to explain what happened`);
    }
  }, [form, match.params.id, orderDetails.id, productOrderList]);

  const handleRemoveProduct = useCallback(
    async (itemOrder, index) => {
      try {
        await api.delete(`orderItems/${itemOrder.id}`);

        const newList = [...productOrderList];
        newList.splice(index, 1);
        setProductOrderList(newList);
        toast.success('Item Deleted');
        const response = await api.get(`orders/${match.params.id}`);
        setOrderDetails(response.data);
      } catch (error) {
        toast.warn(`${error.response.data.error}. Something went wrong. Contact support to explain what happened`);
      }
    },
    [match.params.id, productOrderList]
  );

  const handleSyncOrder = async () => {
    try {
      setLoadingSync(true);
      const syncedOrder = await api.patch('/orders/sync', {
        shipstationOrderId: orderDetails.shipstation_order_id,
        // orderNumber,
        // fulfillmentChannel: orderDetails.fulfillment_channel,
        // orderDate: orderDetails.order_date,
        orderId: orderDetails.id,
      });

      if (syncedOrder.data.error) {
        toast.error(syncedOrder.data.error);
        return;
      }

      const orderData = await api.get(`orders/${orderDetails.id}`);

      setOrderDetails(orderData.data);
      setStoreItemsList(orderData.data.order_items.filter((el) => el.is_rbms_item === false).sort((a, b) => a.id - b.id));
      setProductOrderList(orderData.data.order_items.filter((el) => el.is_rbms_item === true));

      setLoadingSync(false);
      toast.success('Order up to date');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { TextArea } = Input;

  if (error) {
    return <Container>{error}</Container>;
  }

  function showOrderStatus(orderStatus) {
    let tag = '';
    switch (orderStatus) {
      case 'shipped':
        tag = (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Shipped
          </Tag>
        );
        break;
      case 'Saved':
        tag = (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Saved
          </Tag>
        );
        break;
      case 'awaiting_shipment':
        tag = (
          <Tag icon={<ClockCircleOutlined />} color="processing">
            Awaiting Shipment
          </Tag>
        );
        break;
      case 'on_hold':
        tag = (
          <Tag icon={<ClockCircleOutlined />} color="processing">
            On Hold
          </Tag>
        );
        break;
      case 'awaiting_payment':
        tag = (
          <Tag icon={<DollarCircleOutlined />} color="warning">
            Awaiting Payment
          </Tag>
        );
        break;
      case 'cancelled':
        tag = (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Cancelled
          </Tag>
        );
        break;
      default:
        tag = (
          <Tag icon={<ExclamationCircleOutlined />} color="error">
            ORDER WITHOUT STATUS
          </Tag>
        );
        break;
    }
    return tag;
  }

  const handleOrderNotes = async (notes) => {
    await api.put(`orders/${orderDetails.id}`, { notes });
  };

  async function handlePrintPackingSlip() {
    console.log('orderDetails ', orderDetails);
    generatePDF(orderDetails);
  }

  return (
    <Container>
      <Space>
        <Button
          variant="contained"
          type="primary"
          icon={<ArrowLeftOutlined />}
          onClick={() => (searchQuery === null ? history.push('/orders') : history.push(`/orders?searchQuery=${searchQuery}`))}
        >
          Back
        </Button>

        {orderDetails.fulfillment_channel !== 'FBA' && (
          <>
            <Button loading={loadingSync} variant="contained" type="primary" icon={<CloudDownloadOutlined />} onClick={handleSyncOrder}>
              Sync
            </Button>
            {(profile.type === 999 || profile.email === 'hassan.najim@ruggedbooks.com' || profile.email === 'a.haj@ruggedbooks.com') && (
              <Button variant="contained" type="primary" icon={<PrinterOutlined />} onClick={() => history.push(`/orderShipment/${orderDetails.id}`)}>
                Print Label
              </Button>
            )}
          </>
        )}

        <Button variant="contained" type="primary" icon={<PrinterOutlined />} onClick={handlePrintPackingSlip}>
          Print Packing Slip
        </Button>
      </Space>
      <OrderTitle>
        <Space align="top">
          <Paragraph copyable>{unescape(orderNumber)}</Paragraph>
          {showOrderStatus(orderDetails.status)}
        </Space>
        <Typography color="textSecondary">{orderDetails?.store?.name}</Typography>
        {orderDetails.fulfillment_channel === 'FBA' && <Tag color="cyan">FBA</Tag>}
      </OrderTitle>
      <Form form={form} name="orderNotesForm">
        <OrderContainer elevation={1}>
          <OrderArea variant="outlined">
            <Typography variant="h5" component="h2">
              Order Summary
            </Typography>
            <OrderSummary>
              <div>
                <span>RBMS Order Date:</span> <span>{moment(orderDetails.createdAt).format('MMM DD, YYYY')}</span>
              </div>
              <div>
                <span>Marketplace Order Date:</span> <span>{moment(orderDetails.order_date).format('MMM DD, YYYY')}</span>
              </div>
              <div>
                <span>Shipping Date:</span> <span>{orderDetails.status === 'shipped' && moment(orderDetails.ship_date).format('MMM DD, YYYY')}</span>
              </div>
            </OrderSummary>
            <CustomDivider />
            <OrderSummary>
              <div>
                <span>Tax Amount:</span>
                <span>{orderDetails.tax_amount}</span>
              </div>
              <div>
                <span>Shipping Amount:</span> <span>{orderDetails.shipping_amount}</span>
              </div>
              <div>
                <span>Order Total:</span> <span>{orderDetails.order_total}</span>
              </div>
            </OrderSummary>
          </OrderArea>

          <OrderArea variant="outlined">
            <Typography variant="h5" component="h2">
              Ship To
            </Typography>
            <OrderSummary>
              <div>
                <span>Customer:</span> <span>{orderDetails?.customer?.name}</span>
              </div>
              <div>
                <span>Username:</span> <span>{orderDetails?.customer?.customer_username}</span>
              </div>
            </OrderSummary>
            <CustomDivider />
            <OrderSummary>
              <div>
                <span>Shipping Service:</span> <span>{orderDetails.requested_shipping_service}</span>
              </div>
            </OrderSummary>
          </OrderArea>

          <OrderArea variant="outlined">
            <Typography variant="h5" component="h2">
              Notes
            </Typography>
            <ComponentTextArea
              value={orderNotes || ''}
              onChange={(e) => setOrderNotes(e.target.value)}
              onBlur={(e) => handleOrderNotes(e.target.value)}
              placeholder="Add aditional information about this order."
              rows={3}
            />
          </OrderArea>
        </OrderContainer>

        <OrderContainer elevation={1}>
          <OrderAreaProducts variant="outlined">
            <Typography variant="h5" component="h2" display="inline" style={{ padding: '15px' }}>
              Products ordered
            </Typography>
            <Form.Item display="inline">
              <Popconfirm
                placement="bottom"
                open={saveConfirmPopup}
                title="Do you want to save these notes?"
                onConfirm={() => {
                  handleSaveNotes();
                }}
                onOpenChange={() => handleVisibleChange(saveConfirmPopup)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={orderDetails.status === 'cancelled' && profile.type !== 999}
                  style={{ marginLeft: '10px', display: 'inline' }}
                  type="primary"
                  htmlType="button"
                  variant="contained"
                  icon={<SaveOutlined />}
                >
                  Save Notes
                </Button>
              </Popconfirm>
            </Form.Item>

            {orderDetails.order_items && (
              <ProductsTable>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Unit $</th>
                    <th>SKU</th>
                    <th>Qty</th>
                    <th>Total $ (Before tax)</th>
                    <th>Total Tax $</th>
                    <th>Total $</th>
                    <th>Store Notes</th>
                    <th>Manual Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {storeItemsList.map((itemOrder, index) => (
                    <tr key={+index}>
                      <td>{itemOrder.title}</td>
                      <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(itemOrder.unit_price)}</td>
                      <td>{itemOrder.seller_sku}</td>
                      <td>{itemOrder.quantity}</td>
                      <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(itemOrder.total_price)}</td>
                      <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(itemOrder.item_tax)}</td>
                      <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(+itemOrder.total_price + +itemOrder.item_tax)}</td>
                      <td>
                        <Tooltip title={itemOrder.notes} color="blue">
                          {itemOrder.notes && `${itemOrder.notes.substring(0, 100)}...`}
                        </Tooltip>
                      </td>
                      <td>
                        {profile?.type === 1 ? (
                          <Form.Item hasFeedback name={`rbms_notes${index}`} style={{ marginLeft: '5px' }}>
                            <TextArea
                              showCount
                              maxLength={2000}
                              allowClear
                              style={{ width: 250 }}
                              placeholder="Add additional information about this order"
                              rows={4}
                              readOnly
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item hasFeedback name={`rbms_notes${index}`} style={{ marginLeft: '5px' }}>
                            <TextArea
                              showCount
                              maxLength={2000}
                              allowClear
                              style={{ width: 250 }}
                              placeholder="Add additional information about this order"
                              rows={4}
                            />
                          </Form.Item>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ProductsTable>
            )}
          </OrderAreaProducts>
        </OrderContainer>
      </Form>

      <SearchProducts>
        <Form form={form} name="addItemsForm">
          <Typography variant="h5" component="h2">
            Add Items to Order
          </Typography>
          <span>
            <Form.Item name="productTitleRef">
              <DebounceSelect
                allowClear
                showSearch
                value={value}
                placeholder="Products"
                fetchOptions={(value) => fetchItems(value, false, null, null, false)}
                style={{ width: '700px', textTransform: 'uppercase' }}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
              />
            </Form.Item>
            <Form.Item name="productQtyRef">
              <InputNumber min={0} max={99999} placeholder="Qty" />
            </Form.Item>
            <Form.Item hasFeedback name="manualNotes" style={{ marginLeft: '5px' }}>
              <TextArea showCount maxLength={2000} allowClear style={{ width: 250 }} placeholder="Add additional information about this order" rows={2} />
            </Form.Item>

            <Button
              disabled={orderDetails.status === 'cancelled' && profile.type !== 999}
              type="primary"
              shape="circle"
              onClick={handleAddProduct}
              icon={<PlusOutlined />}
            />
          </span>
          {productOrderList.length > 0 && (
            <OrderContainer>
              <OrderAreaProducts>
                <Typography variant="h5" component="h2">
                  Items Added Manually
                </Typography>

                <ProductsTable>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Qty</th>
                      <th>Manual Notes</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productOrderList.map((itemOrder, index) => (
                      <tr key={index}>
                        <td>{itemOrder.title}</td>
                        <td>{itemOrder.quantity}</td>
                        <td>{itemOrder.rbms_notes}</td>
                        <td>
                          {orderDetails.status === 'awaiting_shipment' ||
                          orderDetails.status === 'on_hold' ||
                          orderDetails.status === 'cancelled' ||
                          profile?.type === 999 ? (
                            <Popconfirm
                              onConfirm={() => handleRemoveProduct(itemOrder, index)}
                              title="Are you sure you want to delete?"
                              trigger="click"
                              placement="left"
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button type="primary" icon={<DeleteOutlined />} />
                            </Popconfirm>
                          ) : (
                            <Button type="primary" icon={<DeleteOutlined />} disabled />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </ProductsTable>
              </OrderAreaProducts>
            </OrderContainer>
          )}
        </Form>
      </SearchProducts>

      {orderDetails.fulfillment_channel !== 'FBA' && (
        <OrderContainer elevation={1}>
          <OrderAreaProducts variant="outlined">
            <Typography variant="h5" component="h2">
              Products shipped
              <Link style={{ marginLeft: '10px' }} to={`/ordersEdit?order_id=${orderDetails.id}`}>
                <Button
                  disabled={orderDetails.status === 'cancelled' && profile.type !== 999}
                  style={{ marginLeft: '10px' }}
                  variant="contained"
                  type="primary"
                  icon={<PlusOutlined />}
                  danger={orderDetails.checklist.length >= orderDetails.totalAmountOfItems}
                >
                  Add serial number
                </Button>
                {orderDetails.checklist.length >= orderDetails.totalAmountOfItems && (
                  <Alert
                    message="You can no longer add more items than the number specified in each requested product. If the product requires multiple components to be scanned, you need to add more products to the order."
                    type="warning"
                  />
                )}
              </Link>
            </Typography>

            {orderDetails.checklist && (
              <ProductsTableShipped>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Serial Number</th>
                    <th>Part Number</th>
                    <th>Item</th>
                    <th>Technician</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.checklist.map((itemShipped, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{itemShipped.serial_number}</td>
                      <td>{itemShipped.item?.part_number}</td>
                      <td>{itemShipped.item?.name}</td>
                      <td>{itemShipped.technician.name}</td>
                    </tr>
                  ))}
                </tbody>
              </ProductsTableShipped>
            )}
          </OrderAreaProducts>
        </OrderContainer>
      )}
    </Container>
  );
}
