import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
import moment from 'moment';

export const generatePDF = (data, orientation = 'l') => {
  function groupByItem(checklist) {
    const grouped = {};

    checklist.forEach(({ serial_number, item }) => {
      const { part_number, name } = item;

      if (!grouped[part_number]) {
        grouped[part_number] = { part_number, name, quantity: 0, serial_numbers: [] };
      }
      grouped[part_number].quantity += 1;
      grouped[part_number].serial_numbers.push(serial_number);
    });

    return Object.values(grouped).map((group) => ({
      ...group,
      serial_numbers: group.serial_numbers.join(','),
    }));
  }

  const items = groupByItem(data.checklist);

  const doc = new jsPDF(orientation, 'pt', 'letter');

  doc.setFontSize(16);
  // doc.line(200, 20, 600, 200); // horizontal line
  // doc.table(10, 10, data, ['col1', 'col2', 'col3'], { autoSize: true });
  doc.setFont('helvetica', 'bold');
  doc.text('Packing Slip', doc.internal.pageSize.getWidth() / 2, 50, {
    align: 'center',
  });

  // Get page dimensions
  const pageWidth = doc.internal.pageSize.getWidth();
  // const pageHeight = doc.internal.pageSize.getHeight();

  // Image dimensions
  const imgWidth = 140;
  const imgHeight = 33;

  // Calculate image position - top right corner with some margin
  const imgX = pageWidth - imgWidth - 40; // 40px from right edge
  const imgY = 30; // 30px from top

  // ... (keep your existing text and styling code)

  // Add image with dynamic position
  doc.addImage('/images/logos/rb-logo.png', 'PNG', imgX, imgY, imgWidth, imgHeight);

  // const tableColumnHeaders = Object.keys(data[0]);

  // // Convert data into rows for the table
  // const tableRows = data.map((row) =>
  //   Object.keys(row).map((key) => {
  //     const value = row[key];
  //     return Array.isArray(value) ? value.join(', ') : value;
  //   })
  // );

  // autoTable(doc, { html: '#my-table' });

  // Or use javascript directly:
  doc.setFont('helvetica', 'bold');
  doc.text('Rugged Books Inc.', 20, 50);
  doc.setFontSize(11);
  doc.setFont('helvetica', 'normal');
  doc.text('303 Townline Rd, unit #2', 20, 65);
  doc.text('Niagara-on-the-Lake, Ontario, Canada', 20, 80);

  doc.setFont('helvetica', 'bold');
  doc.text('Ship to: ', 20, 110);

  doc.setFontSize(11);
  doc.setFont('helvetica', 'normal');
  doc.text(data.customer.name, 65, 110);

  let street = '';
  if (data.customer.street1) {
    street = `${data.customer.street1}`;
  }
  if (data.customer.street2) {
    street = ` ${data.customer.street2}`;
  }
  if (data.customer.street3) {
    street = ` ${data.customer.street3}`;
  }
  doc.text(street, 65, 125);
  doc.text(`${data.customer.city}, ${data.customer.state} ${data.customer.postal_code} ${data.customer.country}`, 65, 140);

  doc.setFont('helvetica', 'bold');
  doc.text('Order #: ', 400, 110, { align: 'right' });
  doc.text('Date: ', 400, 125, { align: 'right' });
  // doc.text('User: ', 400, 140, { align: 'right' });
  doc.text('Ship Date: ', 400, 140, { align: 'right' });

  doc.setFontSize(11);
  doc.setFont('helvetica', 'normal');
  doc.text(data.order_num, 400, 110);
  doc.text(data.order_date ? moment(data.order_date).format('MMMM DD, YYYY') : '', 400, 125);
  // doc.text(data.customer.customer_username, 400, 140);
  doc.text(data.ship_date && moment(data.ship_date).isAfter('2017-01-01') ? moment(data.ship_date).format('MMMM DD, YYYY') : '', 400, 140);

  autoTable(doc, {
    startY: 200,
    // theme: 'plain',
    headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
    head: [['Qty', 'Part number', 'Item', 'Serial Numbers']],
    body: items.map(({ quantity, part_number, name, serial_numbers }) => [quantity, part_number, name, serial_numbers]),
    columnStyles: {
      0: { cellWidth: 40, minCellWidth: 40 }, // Fixed width for Qty
      1: { minCellWidth: 100 }, // Minimum width for Part number
      2: { minCellWidth: 150 }, // Minimum width for Item
    },
    styles: {
      overflow: 'linebreak', // Default overflow for all cells
      cellPadding: 5, // Add some padding
    },
    // [
    // [
    //   'CF­31MK6I5V',
    //   'Toughbook CF-31 MK6, 14" FHD, Sunlight Readable, Intel Core i5 7th Gen with Windows 11 Pro.\nToughbook CF-31 MK6, 14" FHD, Sunlight Readable, Intel Core i5 7th Gen with Windows 11 Pro. Toughbook CF-31 MK6, 14" FHD, Sunlight Readable, Intel Core i5 7th Gen with Windows 11 Pro. Toughbook CF-31 MK6, 14" FHD, Sunlight Readable, Intel Core i5 7th Gen with Windows 11 Pro. Toughbook CF-31 MK6, 14" FHD, Sunlight Readable, Intel Core i5 7th Gen with Windows 11 Pro. Toughbook CF-31 MK6, 14" FHD, Sunlight Readable, Intel Core i5 7th Gen with Windows 11 Pro. Toughbook CF-31 MK6, 14" FHD, Sunlight Readable, Intel Core i5 7th Gen with Windows 11 Pro.',
    //   '1',
    // ],
    // [{ content: 'S/N: 0BKKC96272', colSpan: 3, styles: { fontSize: 8 } }],
    // ['-', 'Memory (DDR4-2133MT/s) - (RB-WMBA1416G x2) 32GB (16GB+16GB)', '1'],
    // ['-', 'Main Storage - (RB-311TB) 1TB SSD', '1'],
    // ['-', 'Stylus & Tether - (RA-VNP023U) (RA-VNT002U) Stylus & Tether', '1'],
    // ['-', 'Protect your Screen, Film - Selected', '1'],
    // ],
  });

  // Open the PDF in a new tab and trigger print
  const pdfUrl = doc.output('bloburl');
  const printWindow = window.open(pdfUrl);

  setTimeout(() => {
    printWindow.print();
  }, 500);
};
