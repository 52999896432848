/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

import { LaptopOutlined, SyncOutlined } from '@ant-design/icons';
import { AppBar, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputIcon from '@material-ui/icons/Input';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import MenuIcon from '@material-ui/icons/Menu';
import { Badge, Space } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { signOut } from '~/store/modules/auth/actions';
import { getCurrentCountRequest } from '~/store/modules/itemsUnderMyName/actions';
import { UserInfo } from './styles';
// import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    zIndex: '999',
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}));

export default function Header(props) {
  const dispatch = useDispatch();
  // const dispatchPromise = useDispatchPromise();

  const profile = useSelector((state) => state.user.profile);
  const itemsUnderMyNameCount = useSelector((state) => state.itemsUnderMyName.currentCount);

  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = ['alpha', 'beta', 'development'].includes(process.env.REACT_APP_ENV) ? '/react-orange.svg' : 'favicon.ico';
  }, []);

  document.title = ['alpha', 'beta', 'development'].includes(process.env.REACT_APP_ENV)
    ? `${process.env.REACT_APP_ENV[0].toUpperCase() + process.env.REACT_APP_ENV.slice(1)} RBMS`
    : 'RBMS';

  // const [notifications] = useState([]);

  function handleSignOut() {
    dispatch(signOut());
  }

  function handleUpdateCount() {
    dispatch(getCurrentCountRequest());
  }

  let systemBgColor = '';

  switch (process.env.REACT_APP_ENV) {
    case 'alpha':
      systemBgColor = '#f43f5e';
      break;
    case 'alphallc':
      systemBgColor = '#C8102E';
      break;
    case 'beta':
      systemBgColor = '#fde047';
      break;
    case 'betallc':
      systemBgColor = '#F3CC00';
      break;
    case 'development':
      systemBgColor = '#00B37E';
      break;
    case 'developmentllc':
      systemBgColor = '#007934';
      break;
    case 'productionllc':
      systemBgColor = '#012169';
      break;
    default:
      systemBgColor = '#3b82f6';
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // eslint-disable-next-line no-nested-ternary
      style={{
        backgroundColor: systemBgColor,
      }}
    >
      <Toolbar>
        <RouterLink to="/">
          {/* <img alt="Logo" src="/images/logos/logo--white.svg" /> */}
          {process.env.REACT_APP_ENV && process.env.REACT_APP_ENV.includes('llc') ? (
            <img
              alt="Logo"
              src="/images/logos/logo-llc.svg"
              width={50}
              height={50}
              style={{
                filter: 'drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.8))',
              }}
            />
          ) : (
            <LaptopChromebookIcon fontSize="large" htmlColor="#fff" />
          )}
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge badgeContent={itemsUnderMyNameCount} color="primary" variant="dot">
              <LaptopOutlined />
            </Badge>
          </IconButton> */}
          <Space size="middle">
            <SyncOutlined onClick={handleUpdateCount} style={{ fontSize: '2em', color: 'whitesmoke', cursor: 'pointer' }} />

            <Badge count={itemsUnderMyNameCount}>
              <RouterLink to={`/locationList?location=${process.env.REACT_APP_TECH_LOCATION_ID}&userId=${profile?.id}`}>
                <LaptopOutlined style={{ fontSize: '2em', color: 'whitesmoke', cursor: 'pointer' }} />
              </RouterLink>
            </Badge>
            <UserInfo>
              <RouterLink to="profile">{profile ? profile.name : ''}</RouterLink>
            </UserInfo>
            <IconButton className={classes.signOutButton} color="inherit" onClick={handleSignOut}>
              <InputIcon />
            </IconButton>
          </Space>
        </Hidden>

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

Header.defaultProps = {
  className: '',
  onSidebarOpen: () => {},
};
