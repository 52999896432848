import { PageHeader, Space, Table, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import api from '~/services/api';

import history from '~/services/history';
import { Container } from './styles';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    // render: text => <Link to={`/downstream/${text}`}>{text}</Link>,
  },
  {
    title: 'Shipment date',
    dataIndex: 'shipped_date',
    key: 'shipped_date',
    render: (text) => (text ? moment(text).format('MMMM DD, YYYY') : ''),
  },
  {
    title: 'Collecting Company',
    dataIndex: ['supplier', 'name'],
    key: 'supplier.name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => <Tag color={text === 'open' ? 'success' : 'error'}>{text}</Tag>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <Link to={`/downstreamEdit/${record.id}`}>Edit</Link>
        {/* <a>Invite {record.id}</a> */}
      </Space>
    ),
  },
];

export default function Downstream() {
  const profile = useSelector((state) => state.user.profile);

  const [downstreamList, setDownstreamList] = useState([]);

  useEffect(() => {
    async function loadDownstreamList() {
      const downstreamData = await api.get('downstream');
      setDownstreamList(downstreamData.data);
    }

    async function loadInitialData() {
      const { data: downstreamUsersData } = await api.get('params?service=Downstream&key_param=usersAllowed');

      const usersAllowed = downstreamUsersData.split(';');

      if (profile.type !== 999 && !usersAllowed.includes(profile.email)) {
        history.push('/');
      } else {
        loadDownstreamList();
      }
    }
    loadInitialData();
  }, [profile.email, profile.type]);

  return (
    <Container>
      <PageHeader
        ghost={false}
        // onBack={() => window.history.back()}
        title="Downstream"
        subTitle="Shipment of units to be disposed"
        // extra={[
        //   <div>
        //     Show completed: <Switch defaultChecked />
        //   </div>,
        // ]}
      />
      <Table size="small" rowKey="id" columns={columns} dataSource={downstreamList} pagination={false} />
    </Container>
  );
}
