import { SearchOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Empty, Input, Select, Space, Spin, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { MagnifyingGlass } from 'phosphor-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import InputText from '~/components/InputText';
import api from '~/services/api';

import { Container } from './styles';

export default function Receiving() {
  const [receivings, setReceivings] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterCriteria, setFilterCriteria] = useState('receiving_item_description');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalReceivings, setTotalReceivings] = useState(0);

  const { Option } = Select;
  const searchInputRef = useRef('');

  const handleSearchBar = useCallback(
    async (searchInput) => {
      try {
        setLoading(true);
        setReceivings([]);

        const valueTrimmed = searchInput?.trim().toUpperCase();

        const serialNumberFormatted =
          valueTrimmed?.substring(0, 4) === 'P/N:' ? valueTrimmed?.substring(Number(valueTrimmed?.indexOf('S/N:')) + 4) : valueTrimmed;

        searchInputRef.current.value = serialNumberFormatted || '';

        const { data: receivingData } = await api.get('receiving', {
          headers: {
            filterCriteria,
            filterText: serialNumberFormatted?.trim().toUpperCase() || searchInputRef.current.value || '',
          },
          params: {
            page: currentPage,
            pageSize,
          },
        });

        if (receivingData.count > 0) {
          setReceivings(receivingData.rows.map((e) => ({ key: e.id, ...e })));
          setTotalReceivings(receivingData.count); // Set the total count
        } else {
          toast.error('No receivings found! Try to use the Search Option within the Columns for more precise results.');
          setTotalReceivings(0);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [filterCriteria, currentPage, pageSize]
  );

  const tagColor = {
    complete: {
      label: 'Assessment Complete',
      color: 'green',
    },
    complete_receiving_parts: {
      label: 'Complete Receiving of Parts',
      color: 'green',
    },
    pending_assessment: {
      label: 'Assessment Pending',
      color: 'yellow',
    },
    pending_receiving: {
      label: 'Receiving Pending',
      color: 'blue',
    },
    partially_received: {
      label: 'Partially Received',
      color: 'yellow',
    },
    null: {
      label: 'No Status',
      color: 'magenta',
    },
  };

  useEffect(() => {
    async function loadReceivings() {
      setLoading(true);
      const { data: receivingData } = await api.get('receiving', {
        params: {
          page: currentPage,
          pageSize,
        },
      });

      if (receivingData.count > 0) {
        setReceivings(receivingData.rows.map((e) => ({ key: e.id, ...e })));
        setTotalReceivings(receivingData.count); // Set the total count
      } else {
        setReceivings([]);
        setTotalReceivings(0);
      }
      setLoading(false);
    }
    loadReceivings();
  }, [currentPage, pageSize]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const expandedRowRender = ({ id, purchase_order, receiving_items, receiving_pictures }) => {
    receiving_items = [...receiving_items.map((e, i) => ({ key: i, ...e }))];
    const pictures = [...receiving_pictures.map((e, i) => ({ key: i, path: e.files.path }))];

    const columns = [
      { title: 'Quantity Received', dataIndex: 'quantity', key: 'quantity', width: 180 },
      { title: 'Quantity to Receive', dataIndex: 'quantity_to_receive', key: 'quantity_to_receive', width: 180 },
      {
        title: 'Inventory',
        width: 150,
        key: 'action',
        dataIndex: 'action',
        render: (_text, row) => <Link to={`/inventoryOfReceiving?id=${id}&receivingItemId=${row.id}&po=${purchase_order}`}>Click to add Inventory</Link>,
      },
      { title: 'Description', dataIndex: 'description', key: 'description' },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => (
          <Tag color={tagColor[text]?.color} key={tagColor[text]?.label}>
            {tagColor[text]?.label.toUpperCase() || text}
          </Tag>
        ),
      },
    ];

    const pictureColumns = [
      {
        title: 'Picture',
        dataIndex: 'path',
        key: 'path',
        render: (text, row) => <a target="_blank" rel="noopener noreferrer" href={`https://api.ruggedbooksms.com/files/${text}`}>{`Picture ${row.key + 1}`}</a>,
      },
    ];

    return (
      <>
        <Table columns={columns} dataSource={receiving_items} pagination={false} />
        <Table columns={pictureColumns} dataSource={pictures} pagination={false} />
      </>
    );
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();

    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText('');
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    };
  }

  const columns = [
    { title: 'Id', dataIndex: 'id', ...getColumnSearchProps('id') },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 200,
      render: (text) => moment(text).format('MMM DD, YYYY'),
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tracking / BoL',
      dataIndex: 'tracking_lading',
      key: 'tracking_lading',
      sorter: (a, b) => a.tracking_lading.length - b.tracking_lading.length,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('tracking_lading'),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      key: 'carrier',
    },
    {
      title: 'Purchase Order',
      dataIndex: 'purchase_order',
      key: 'purchase_order',
      ...getColumnSearchProps('purchase_order'),
      render: (text) => <Link to={`/purchaseOrders/${text}?flag=true`}>{text}</Link>,
    },
    {
      title: 'RMA #',
      dataIndex: 'rma_number',
      key: 'rma_number',
      ...getColumnSearchProps('rma_number'),
      render: (text) => <Link to="/returns">{text}</Link>,
    },
    {
      title: 'Service #',
      dataIndex: 'service_number',
      key: 'service_number',
      ...getColumnSearchProps('service_number'),
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
      ...getColumnSearchProps('supplier'),
    },

    {
      title: 'Received By',
      dataIndex: 'created_by',
      key: 'created_by',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      ...getColumnSearchProps('notes'),
    },
  ];

  if (receivings.length === 0) {
    return (
      <Empty
        description={<span>No data</span>}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '60vh',
        }}
      />
    );
  }

  return (
    <Container>
      <Card size="small" bordered={false}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space wrap>
            <Select
              onChange={(value) => {
                setFilterCriteria(value);
                searchInputRef.current.value = '';
                searchInputRef.current.focus();
              }}
              value={filterCriteria}
              style={{ width: 200 }}
            >
              <Option value="receiving_item_description">Receiving Item Description</Option>
              <Option value="serial_number">Serial Number</Option>
            </Select>

            <Tooltip title="Use this search bar for options available in the list only. If you require to search for other information, such as Purchase Order, Tracking Number, Supplier, etc, please use the Lookup Search Button available on each of the columns below.">
              <InputText
                ref={searchInputRef}
                placeholder="Search"
                IconAfterProp={<MagnifyingGlass size={20} />}
                autoFocus
                style={{ width: 250 }}
                onPressEnter={handleSearchBar}
              />
            </Tooltip>
          </Space>
        </div>
      </Card>

      {loading ? (
        <Spin tip="Loading...">
          <Alert
            message="Search is looking for the items. Hold on a second"
            description="If the search takes too long, wait until something is loaded and then search again for more specific wording"
            type="info"
          />
        </Spin>
      ) : receivings.length > 0 ? (
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: expandedRowRender,
            rowExpandable: (record) => record.name !== 'Not Expandable',
          }}
          dataSource={receivings}
          pagination={{ pageSize, current: currentPage, total: totalReceivings }}
          onChange={handleTableChange}
          size="small"
        />
      ) : (
        <Empty
          description={<span>No data</span>}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '60vh',
          }}
        />
      )}
    </Container>
  );
}
