import api from '~/services/api';

export async function checkReadingType(reading) {
  const invalidItemStatus = ['Sold', 'Gift', 'InUse'];

  const readingType = reading.substring(0, reading.indexOf(':'));

  switch (readingType) {
    case 'P/N': {
      const serialNumber = reading.substring(reading.indexOf('S/N:') + 4);

      const inventoryItem = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumber });
      if (inventoryItem.data.error) {
        return { error: inventoryItem.data.error };
      }

      if (invalidItemStatus.includes(inventoryItem.data.status)) {
        return { error: `This item is marked as ${inventoryItem.data.status} and cannot be transferred` };
      }

      const fromLocationInfo = await api.get(`/locations/${inventoryItem.data.location_id}`);
      if (fromLocationInfo.data.error) {
        return { error: fromLocationInfo.data.error };
      }
      const fromUserInfo = inventoryItem.data.user_id ? await api.get(`/users/${inventoryItem.data.user_id}`) : null;

      const fromLocationId = inventoryItem.data.location_id;
      const fromLocationName = fromLocationInfo.data.name;
      const fromUserId = inventoryItem.data.user_id;
      const fromUserName = fromUserInfo?.data?.name;

      return { type: 'P/N', fromLocationId, fromUserId, fromLocationName, fromUserName, inventoryItemId: inventoryItem.data.id, serialNumber };
    }
    case 'LCT': {
      const locationName = reading.substring(reading.indexOf(':') + 1);

      const locationInfo = await api.get(`/locations/getLocationByName/${locationName}`);

      if (!locationInfo.data) {
        return { error: 'Invalid location' };
      }

      const toLocationId = locationInfo.data.id;
      const toLocationName = locationInfo.data.name;
      return { type: 'LCT', toLocationId, toLocationName };
    }
    case 'USR': {
      const userQrCode = reading.substring(reading.indexOf(':') + 1);
      const buff = Buffer.from(userQrCode, 'base64');
      const userEmail = buff.toString('utf-8');

      const userInfo = await api.get(`/users/getByEmail/${userEmail}`);

      if (!userInfo.data) {
        return { error: 'Invalid user' };
      }

      const toUserId = userInfo.data.id;
      const toUserName = userInfo.data.name;
      const toUserEmail = userInfo.data.email;

      return { type: 'USR', toLocationId: Number(process.env.REACT_APP_TECH_LOCATION_ID), toUserId, toUserName, toUserEmail };
    }
    default: {
      // assuming that a serial number was read from the unit not using our QR code
      const serialNumber = reading.toUpperCase();
      const inventoryItem = await api.post('inventory_items/showBySerialNumber', { serial_number: serialNumber });
      if (inventoryItem.data.error) {
        return { error: inventoryItem.data.error };
      }

      if (invalidItemStatus.includes(inventoryItem.data.status)) {
        return { error: `This item is marked as ${inventoryItem.data.status} and cannot be transferred` };
      }

      const fromLocationInfo = await api.get(`/locations/${inventoryItem.data.location_id}`);
      if (fromLocationInfo.data.error) {
        return { error: fromLocationInfo.data.error };
      }

      const fromUserInfo = inventoryItem.data.user_id ? await api.get(`/users/${inventoryItem.data.user_id}`) : null;

      const fromLocationId = inventoryItem.data.location_id;
      const fromLocationName = fromLocationInfo.data.name;
      const fromUserId = inventoryItem.data.user_id;
      const fromUserName = fromUserInfo ? fromUserInfo.data.name : '';

      return { type: 'P/N', fromLocationId, fromUserId, fromLocationName, fromUserName, inventoryItemId: inventoryItem.data.id, serialNumber };
    }
  }
}
