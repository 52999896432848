import React from 'react';

import { Modal } from 'antd';
import api from '~/services/api';
import { Table } from '../styles';

export async function handleItemTransfer(items, transferType) {
  const info = (returnCounterArray) => {
    Modal.info({
      title: 'ALERT: this item contains components that have been previously returned by clients. Shipping this item will require tech manager’s approval',
      content: (
        <div>
          <h3 style={{ fontWeight: 700 }}>Components in this item that have been previously returned</h3>
          <Table>
            <thead>
              <tr key="header">
                <th>SN</th>
                <th>PN</th>
                <th>Times Returned</th>
              </tr>
            </thead>
            <tbody>
              {returnCounterArray.map((el) => {
                return (
                  <tr key={el.serial_number}>
                    <td>{el.serial_number}</td>
                    <td>{el.part_number}</td>
                    <td>{el.return_counter}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ),
      onOk() {},
      width: '700px',
    });
  };

  if (transferType === 'now') {
    try {
      const transferResponse = await api.post('/locations/handleChangeLocationOfUnits', {
        items,
      });

      if (!transferResponse.data.ok) {
        return { error: transferResponse.data.error };
      }
      if (transferResponse.data.returnCounter.length > 0) info(transferResponse.data.returnCounter);

      return { ok: true };
    } catch (error) {
      return { ok: false, error };
    }
  }
  return { ok: true };
}
